



























import Vue from "vue";
import ViewJob from "@/components/recruiter/jobs/job_listing/ViewJob.vue";
import {
  JobsWithCandidates,
  SearchedCandidate
} from "@/store/modules/recruiter/interfaces";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { FETCH_MATCHING_CANDIDATES } from "@/store/modules/recruiter/constants";
import { parseStrToInt } from "@/utils/global";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import { GET_JOB_BY_ID } from "@/store/modules/common/constants";
import ViewJobLoading from "@/components/recruiter/jobs/ViewJobLoading.vue";

export default Vue.extend({
  name: "ViewJobWithCandidates",
  components: { ViewJobLoading, ViewJob },
  beforeRouteEnter(to, from, next) {
    const param_id = to.params.id;
    if (!param_id || !parseStrToInt(param_id)) next(false);
    else next();
  },
  beforeRouteUpdate(to, from, next) {
    const param_id = to.params.id;
    if (!param_id || !parseStrToInt(param_id)) next(false);
    else next();
  },
  data() {
    return {
      job: null as null | JobsWithCandidates,
      loading: true
    };
  },
  computed: {
    ...mapGetters("recruiter", {
      get_job_with_candidates: FETCH_MATCHING_CANDIDATES
    })
  },
  async mounted() {
    this.loading = true;
    const jid = parseStrToInt(this.$route.params.id);
    if (!jid) {
      this.root_error("Invalid Job");
      await this.$router.push("/recruiter/jobs");
      return;
    }
    // If the job exists in store => get
    const response = this.get_job_with_candidates(jid);
    if (response) {
      this.job = response;
      this.loading = false;
      return;
    }
    // If the job not exists in store => fetch from backend
    await this.process_job_from_backend(jid);
  },
  methods: {
    ...mapMutations({
      root_error: ROOT_ERROR
    }),
    ...mapActions("common", {
      fetch_job_by_id: GET_JOB_BY_ID
    }),
    ...mapActions("recruiter", {
      fetch_matching_candidates: FETCH_MATCHING_CANDIDATES
    }),
    async process_job_from_backend(jid: number) {
      // Fetching job
      const response = await this.fetch_job_by_id(jid);
      if (!response) {
        this.root_error("Invalid Job");
        await this.$router.push("/recruiter/jobs");
        this.loading = false;
        return;
      }
      this.job = response;
      // Fetching matching candidates
      const matching_candidates = await this.fetch_matching_candidates({
        job_ids: [jid]
      });
      if (matching_candidates && this.job) {
        this.job.matching_candidates = (
          matching_candidates.results || []
        ).filter((candidate: SearchedCandidate) => {
          if (candidate.score) return candidate.score >= 0.2;
        });
      }
      this.loading = false;
    }
  }
});
